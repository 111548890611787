<template>
  <div class="carousel2" id="carousel2">
    <h2>{{ carousel2.carouselTitle }}</h2>

    <p>
      {{ carousel2.carouselText }}
    </p>

    <VueSlickCarousel v-bind="settings">
      <div class="carousel2__item" v-for="(item, index) in filteredItems">
        <img
          class="carousel2__image"
          :src="item.url"
          :alt="`food image ${index}`"
        />
      </div>

      <template #prevArrow>
        <img
          class="carousel2__arrow-prev"
          src="@/assets/elements/left-arrow.svg"
        />
      </template>

      <template #nextArrow>
        <img
          class="carousel2__arrow-next"
          src="@/assets/elements/right-arrow.svg"
        />
      </template>
    </VueSlickCarousel>

    <div class="carousel2__button-parent">
      <button
        v-for="category in carousel2.categories"
        @click="currentCategory = category.category"
        class="carousel2__button"
        :class="{
          'carousel2__button--active': currentCategory === category.category,
        }"
      >
        <span>{{ category.category }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "CategoryCarousel",
  components: { VueSlickCarousel },

  data() {
    return {
      currentCategory: null,
      settings: {
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1230,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    filteredItems() {
      if (this.carousel2 && this.currentCategory) {
        const category = this.carousel2.categories.find(
          (cat) => cat.category === this.currentCategory
        );

        return category.carouselImages;
      }

      return [];
    },
  },
  props: {
    carousel2: Object,
  },
  watch: {
    "carousel2.categories": {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.currentCategory = newVal[0].category;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.carousel2 {
  margin: auto;
  max-width: 1400px;
  padding-inline: 5rem;
  margin-top: -7.5rem;
  padding-top: 7.5rem;

  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  p {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  @media (max-width: $tablet) {
    max-width: 590px;
    padding-inline: 0.5rem;
    padding-top: 10rem;
    margin-top: -1rem;
  }

  &__item {
    padding-inline: 10px;
  }

  &__image {
    width: 100%;
    max-width: 422px;
    border-radius: 17px;

    @media (max-width: $tablet) {
      max-width: 590px;
    }
  }

  &__arrow-prev {
    width: 45px;
    height: 45px;
    transform: translate(-50px, -22.5px);

    @media (max-width: $tablet) {
      transform: translate(40px, -22.5px);
      z-index: 1;
    }
  }

  &__arrow-next {
    width: 45px;
    height: 45px;
    transform: translate(50px, -22.5px);

    @media (max-width: $tablet) {
      transform: translate(-40px, -22.5px);
    }
  }

  &__button-parent {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding-block: 2rem;
  }

  &__button {
    cursor: pointer;
    background-color: var(--primary);
    border-radius: 20px;
    padding-block: 0.8rem;
    padding-inline: 1.5rem;
    color: var(--secondary);

    @media (max-width: $tablet) {
      padding-block: 0.5rem;
      padding-inline: 1rem;
    }

    span {
      font-size: 24px;
      text-align: center;

      @media (max-width: $tablet) {
        font-size: 18px;
      }
    }

    &--active {
      background-color: #872b1e;
    }
  }
}
</style>
