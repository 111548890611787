<template>
  <div class="hero-section">
    <div class="hero-section__text-section">
      <h1>{{ heroTitle }}</h1>
      <p>
        {{ heroText }}
      </p>

      <Tryfree
        :registerPreText="registerPreText"
        :registerTitle="registerTitle"
        :registerPostText="registerPostText"
        :showDisclaimer="false"
        :alignCenter="false"
      />
    </div>

    <img class="hero-section__image" :src="heroImage" alt="Matrett bakgrunn" />
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import Tryfree from "@/components/Tryfree.vue";

export default {
  props: {
    heroTitle: String,
    heroText: String,
    heroImage: String,
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
  },
  components: {
    ButtonComponent,
    Tryfree,
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 2100,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.hero-section {
  overflow: hidden;

  display: flex;
  align-items: center;

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 3.125rem;
  }

  @media (max-width: $mobile) {
    gap: 0rem;
  }

  &__text-section {
    margin-left: 10%;

    p {
      margin-top: 1rem;

      @media (min-width: $tablet) {
        font-size: clamp(0.75rem, 1.7vw, 1.5rem);
      }

      @media (max-width: $tablet) {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: $tablet) {
      margin: 0;
      order: 2;
      padding: 0rem 1.18rem;
      max-width: 590px;

      h1 {
        font-size: 2.625rem;
      }
    }

    @media (max-width: $mobile) {
      background-color: var(--secondary);
      position: relative;
      z-index: 1;

      padding-top: 50px;
    }
  }

  &__text-section > * {
    text-align: start;

    @media (max-width: $tablet) {
      text-align: center;
    }
  }

  &__image {
    position: relative;
    width: 50%;
    top: -100px;
    right: -175px;

    @media (max-width: $tablet) {
      width: 631px;
      height: 634px;
      top: 50px;
      right: -25px;

      order: 1;
    }

    @media (max-width: $mobile) {
      width: 500px;
      height: 500px;
      top: -5px;
      right: -7px;
      order: 1;

      margin-bottom: -140px;
    }
  }
}
</style>
