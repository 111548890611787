import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import Home from "@/views/Home.vue";
import Terms from "@/views/Terms.vue";
import Cookies from "@/views/Cookies.vue";
import Error from "@/views/Error.vue";
import ThankYou from "@/views/ThankYou.vue";
import FAQ from "@/views/FAQ.vue";
import Legal from "@/views/Legal.vue";
import Privacy from "@/views/Privacy.vue";

Vue.use(VueMeta);
Vue.use(VueRouter);

function getRoutes() {
  return [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/terms",
      name: "terms",
      component: Terms,
    },
    {
      path: "/privacy",
      name: "privacy",
      component: Privacy,
    },
    {
      path: "/cookies",
      name: "cookies",
      component: Cookies,
    },
    {
      path: "/thank-you",
      name: "thankyou",
      component: ThankYou,
    },
    {
      path: "/faq",
      name: "faq",
      component: FAQ,
    },
    {
      path: "/legal",
      name: "legal",
      component: Legal,
    },
    {
      path: "/:catchAll(.*)",
      name: "error",
      component: Error,
    },
  ];
}

const routes = getRoutes();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) return { x: 0, y: 0 };
  },
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       el: to.hash,
  //       behavior: "smooth",
  //     };
  //   }
  //   return { x: 0, y: 0 }; // Go to the top of the page if no hash
  // },
});

export default router;
