<template>
  <div class="howitworks" id="howitworks">
    <h2>{{ howItWorks.title }}</h2>

    <div class="howitworks__flex">
      <div class="howitworks__card">
        <p class="howitworks__title">{{ howItWorks.titleOne }}</p>

        <p class="howitworks__subtitle">
          {{ howItWorks.textOne }}
        </p>
      </div>

      <div class="howitworks__card">
        <p class="howitworks__title">{{ howItWorks.titleTwo }}</p>

        <p class="howitworks__subtitle">
          {{ howItWorks.textTwo }}
        </p>
      </div>

      <div class="howitworks__card">
        <p class="howitworks__title">{{ howItWorks.titleThree }}</p>

        <p class="howitworks__subtitle">
          {{ howItWorks.textThree }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    howItWorks: Object,
  },
};
</script>

<style lang="scss">
.howitworks {
  max-width: 1200px;
  margin: auto;
  padding-top: 7.5rem;
  margin-top: -2.5rem;

  @media (max-width: $tablet) {
    padding: 0rem 1.18rem;
    padding-top: 7.5rem;
    margin-top: -2.5rem;
  }

  h2 {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  &__flex {
    display: flex;
    justify-content: start;
    gap: 2.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: $tablet) {
      gap: 1rem;
    }

    @media (max-width: 870px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    font-size: 2.125rem;
    font-weight: bold;
  }

  &__subtitle {
    margin-top: 1rem;
  }

  &__card {
    max-width: 370px;
    border-radius: 15px;
    padding: 3rem;
    background-color: var(--primary);

    p {
      color: var(--secondary-contrast);
    }
  }

  &__margin {
    margin-bottom: 4rem !important;
  }
}
</style>
