<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <div class="terms-container__text">
        <h1>Personvernerklæring</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                Vi jobber hele tiden for å gi deg gode brukeropplevelser på
                Matvarehuset sin nettside. For å få til dette samler vi inn
                informasjon om brukermønster og persondata. Når du går inn som
                bruker på nettstedet www.matvarehuset.no og andre tilknyttede
                nettsteder som eies og driftes av Matvarehuset AS, godtar du
                vilkårene nedenfor. Innholdet i ansvarserklæringen kan endres
                fortløpende. Du bør derfor lese gjennom erklæringen når du
                besøker nettstedet neste gang.
              </span>
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Opphavsrett</span><br />

              Innholdet på Matvarehusets nettsteder er underlagt
              opphavsrettsbeskyttelse. Innholdet kan ikke fritt distribueres,
              kopieres og/eller lagres. Ved ønske om å bruke innhold fra
              Matvarehusets nettsteder kan en ta kontakt via vårt kontaktskjema
              og be om samtykke til å bruke innholdet. Dersom dette samtykket
              gis er det under forutsetning av at kilden til materialet angis
              riktig.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Ansvarserklæring</span><br />

              Matvarehuset anstrenger seg for å holde innholdet på sine
              nettsteder oppdatert og så korrekt som mulig. Det kan likevel
              forekomme at noe informasjon kan være ufullstendig, unøyaktig
              eller ikke oppdatert. Matvarehuset påtar seg intet ansvar for
              eventuelle unøyaktigheter eller utelatelser i opplysningene.
              Enhver beslutning du tar på basis av opplysningene på
              Matvarehusets nettsteder skjer på ditt eget ansvar. Matvarehuset
              påtar seg ikke noe ansvar for tap eller skade av noen art som
              måtte oppstå av bruken av Matvarehusets nettsteder, eller
              opplysninger som finnes der. Matvarehusets nettsteder kan
              inneholde lenker til andre websider utenfor Matvarehusets
              kontroll. Matvarehuset legger inn slike lenker for å hjelpe
              nettstedets besøkende til å finne mer informasjon innen spesifikke
              områder. Matvarehuset kan ikke garantere at slik lenket
              informasjon er korrekt, relevant, aktuell eller fullstendig.

              <br />
              <br />

              Henvisninger til hvilket som helst kommersielt produkt, prosess
              eller tjeneste, eller bruken av bransje, firma eller juridisk
              person på alle Matvarehusets nettsider, er kun ment som
              informasjon og service for nettstedets besøkende og innebærer
              ingen godkjennelse, anbefaling, støtte eller favorisering fra
              Matvarehuset sin side.

              <br />
              <br />

              Matvarehuset kan ikke garantere at de fargene som vises på
              nettsiden er eksakt like de fargene som produkter, ingredienser
              etc. har i virkeligheten. Hvordan fargen oppfattes kan skyldes
              innstillinger på din datamaskin.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Personvernerklæring</span>

              <br />
              Matvarehuset behandler alle personopplysninger som navn, adresse,
              telefonnummer eller annen personlig informasjon du gir til
              Matvarehuset i forbindelse med konkurranser, nyhetsbrev,
              markedsføring, påmeldinger, bestillinger og annet, i henhold til
              gjeldende personopplysningslov. Opplysningene brukes kun til det
              du har gitt ditt samtykke til, for å oppfylle en avtale eller for
              å oppfylle Matvarehusets rettslige forpliktelser.

              <br />
              <br />

              Dersom du har samtykket til å motta nyhetsbrev fra Matvarehuset,
              vil vi kunne foreta en personlig tilpasning slik at
              markedsføringen oppleves relevant for mottakerne. Før slik
              behandling starter, vil du gis utfyllende informasjon og en enkel
              mulighet til å velge å motta generelle tilbud og nyhetsbrev i
              stedet for tilpassede.

              <br />
              <br />

              Matvarehuset vil slette personopplysningene når Matvarehuset har
              håndtert din henvendelse, oppfylt en avtale eller Matvarehusets
              rettslige forpliktelse, eller når du trekker samtykket tilbake ved
              å kontakte oss som forklart nedenfor.

              <br />
              <br />

              Alternativt til sletting, kan Matvarehuset fjerne identifiserende
              opplysninger fra personopplysningene, slik at opplysningene ikke
              lenger kan knyttes til deg (anonymisering). Slik anonym
              informasjon kan Matvarehuset bruke i sitt erfaringsarkiv og for å
              utarbeide analyser og statistikk, for å tilpasse og utvikle
              Matvarehusets markedsføring og produkter i forhold til
              forbrukernes ønske og behov.

              <br />
              <br />

              Personopplysningene vil lagres på Matvarehusets egne servere og
              hos skytjeneste-leverandører som støtter norsk
              personopplysningslov (GDPR). I tilfeller der Matvarehuset
              samarbeider med tredjeparter som behandler personopplysninger skal
              databehandleravtaler mellom Matvarehuset og tredjepart foreligge.
              Matvarehuset overleverer ikke personopplysningene dine til andre
              tredjeparter med mindre du har samtykket til det.

              <br />
              <br />

              For å følge med på og svare på henvendelser som kommer på
              Matvarehuset sine kontoer på Facebook og Instagram benyttes
              verktøyet Facebook Business Manager. Her får vi ikke inn noe mer
              informasjon enn det som allerede er tilgjengelig på den aktuelle
              sosiale medier plattformen.
              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>Kontakt oss på:</span><br />
              <span>firmapost@Matvarehuset.no</span><br />
              <span>eller ring vårt kundesenter +47 400 06 499</span><br />
              <span
                >om du vil ha innsyn i, få utlevert, korrigert eller slettet
                personopplysninger som er lagret om deg.</span
              ><br />
              <br />
              Du kan på samme måte protestere mot, kreve begrensning av eller
              trekke tilbake samtykke til behandlingen av personopplysninger.
              For øvrig har du mulighet til å klage over behandlingen av
              personopplysninger til Datatilsynet.
              <br />
            </p>
          </div>
        </div>

        <Orangebutton
          label="Hjem"
          :destination="{ path: 'home' }"
          class="terms-container__button-parent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import Orangebutton from "@/components/Orangebutton.vue";

export default {
  components: {
    ButtonComponent,
    Orangebutton,
  },
};
</script>

<style lang="scss">
.terms-container-parent {
  @media (max-width: $mobile) {
    position: relative;
    overflow-x: clip;
  }
}

.terms-container {
  text-align: center;

  padding-top: 200px;

  @media (max-width: $tablet) {
    width: 95%;
  }

  @media (max-width: $mobile) {
    width: 100%;
    margin-top: 0px;
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 1.125rem;
      }
    }
  }

  h2 {
    margin-top: 16px;
  }

  p {
    line-height: 1.6rem;
  }

  &__button-parent {
    display: flex;
    justify-content: start;
    margin-top: 5rem;

    @media (max-width: $tablet) {
      justify-content: center;
    }
  }

  span {
    font-weight: 1000;
    font-family: var(--paragraph-font);
    line-height: 26px;

    @media (max-width: $mobile) {
      font-size: 1.375rem;
    }
  }
}
</style>
