<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <div class="terms-container__text">
        <h1>Vilkår og betingelser</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>1. Generelt</span><br />

              Disse vilkårene og betingelsene gjelder for alle avtaler om
              levering av middager og desserter fra Matvarehuset til
              hjemmeboende seniorer. Ved å bestille våre tjenester, godtar du
              disse vilkårene.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>2. Tjenestebeskrivelse</span><br />

              Matvarehuset tilbyr en løpende abonnementstjeneste for ukentlig
              levering av ferdiglagde middager og desserter til hjemmeboende
              seniorer.
              <br />
              <br />
              Abonnementstjenesten løper på månedlig basis inntil den sies opp
              av deg eller Matvarehuset. Oppsigelsestiden er en måned, og
              oppsigelsen trer i kraft etter utløpet av påfølgende måned. Du kan
              si opp abonnementet ved å ringe eller sende e-post til
              Matvarehuset sitt kundesenter.
              <br />
              <br />
              Maten leveres i porsjonspakninger og er tilberedt etter
              kok/kjøl-prinsippet for å sikre kvalitet og holdbarhet. Menyen er
              variert og ernæringsriktig sammensatt for seniorer, og det er
              mulig å både gjøre smakstilpasninger og velge ut ifra et stort
              utvalg av ulike diettalternativer.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>3. Bestilling og levering</span>

              <br />
              Bestillinger må gjøres via vår nettside, telefonsalg, stand/messe
              eller dørsalg. Du må være over 18 år, og ha juridisk rett til å
              inngå avtaler for deg selv eller den du bestiller på vegne av.

              <br />
              <br />

              Levering skjer ukentlig på avtalt dag og tid. Kunden må være
              hjemme for å motta leveransen, eller avtale en sikker plassering
              for levering. Maten leveres i hånd, i kjøleskap eller på dør.
              Matvarehuset kan ta imot og oppbevare nøkler på en sikker måte.

              <br />
              <br />

              Matvarehuset vil etterstrebe at leveransen blir gjennomført, og
              har lang erfaring med utfordringer som seniorer kan ha med hørsel
              eller vansker med å komme seg til døren. Hvis levering likevel
              ikke kan utføres i samsvar med noen av alternativene ovenfor, har
              Matvarehuset rett til å belaste hele verdien av bestillingen.
              Dersom du ber om en ekstra leveranse, vil du være ansvarlig for
              ekstra fraktkostnader som påløper.

              <br />
              <br />

              I de tilfeller der leveransen er satt igjen ved leveringsadressen
              din, er risikoen overdratt til deg og Matvarehuset tar ikke
              ansvaret for forringet eller redusert kvalitet på leveransen som
              følge av vær, temperatur, dyr eller andre eksterne faktorer etter
              at leveransen er blitt levert.

              <br />
              <br />

              Vi leverer til adresser i Norge som er innenfor vårt definerte
              leveringsområde, som stadig utvides. Ta kontakt for å sjekke om vi
              kan levere til deg. Det er også mulig å hente maten selv hos
              Matvarehuset sin lokasjon.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>4. Kvalitet og ansvar</span>

              <br />

              Vi garanterer at maten leveres i henhold til gjeldende
              kvalitetsstandarder og er trygg å spise innenfor angitt
              holdbarhetsdato.

              <br />
              <br />

              Matvarehuset er kun ansvarlig for tap ved produktet hvis det er
              påvist feil eller mangler.  Matvarehuset er ikke ansvarlig for
              forsinkelser eller mangler som skyldes forhold utenfor vår
              kontroll, som værforhold, trafikkproblemer eller andre forhold som
              vi ikke med rimelighet kan forventes å unngå eller overvinne
              konsekvensene av.

              <br />
              <br />

              Matvarehuset er ikke ansvarlig for indirekte tap som følge av
              mangler, med mindre tapet forårsakes av grov uaktsomhet eller
              forsett fra Matvarehusets side. Eventuelle erstatningskrav følger
              alminnelige erstatningsrettslige regler ved forbrukerkjøp.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>5. Angrerett og klage</span>

              <br />
              <br />

              <span>Angrerett:</span>
              <br />
              Matvarehuset følger gjeldende regler og forskrifter for heving av
              forbrukerkjøp. Produktene og tjenestene består hovedsakelig av
              matvarer, som ikke omfattes av angreretten, jf. angrerettloven
              (lov 2014-06-20-27 om opplysningsplikt og angrerett ved fjernsalg
              og salg utenom faste forretningslokaler) § 22. Derfor kan
              angreretten din være begrenset for varer som forringes raskt eller
              har kort holdbarhet, samt forseglede varer som av helse- og
              hygienemessige årsaker ikke kan returneres hvis forseglingen er
              brutt etter levering.

              <br />
              <br />

              <span>Klage:</span>
              <br />
              Du må fremsette klager innen rimelig tid etter at du har oppdaget
              eller burde ha oppdaget en mangel ved leveransen, og senest i
              løpet av leveringsuken. Eventuelle spørsmål eller klager angående
              leveransen eller produktene skal rettes til Matvarehuset sitt
              kundesenter via e-post eller telefon 400 06 499.

              <br />
              <br />

              Matvarehuset vil vurdere hver klage og, dersom den godkjennes,
              kompensere kunden med produktets verdi som et tilgodebeløp eller
              som trekkes fra neste bestilling. Alternativt kan Matvarehuset
              velge å rette feilen eller levere et nytt produkt, så fremt dette
              ikke medfører urimelige kostnader for Matvarehuset. For øvrig
              vises det til forbrukerkjøpsloven.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>6. Innhold og allergier</span>
              <br />
              Matvarehuset tilpasser produkter og tjenester basert på kundens
              spesifikke behov, inkludert allergier og overfølsomhet. Disse
              tilpasningene må avtales direkte med Matvarehuset via telefon
              eller e-post, og medfører et pristillegg.

              <br />
              <br />
              Ved allergier eller spesielle behov er kunden selv ansvarlig for å
              sjekke innholdet før tilberedning. Informasjon om innhold og
              allergener finner du ved å lese innholdsfortegnelsen til
              produktene. Matvarehuset er ikke ansvarlig for feil i
              informasjonen fra produsenten om ingredienser eller allergener.

              <br />
              <br />

              Kunden er ansvarlig for å oppbevare og tilberede råvarer og mat på
              en sikker måte etter at leveringen har funnet sted.
              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>7. Betaling</span>
              <br />
              Betaling skjer etterskuddsvis hver måned via faktura eller
              automatisk trekk fra konto. Faktura kan sendes til enten senior
              eller pårørende.
              <br />
              <br />

              Ved forsinket betaling påløper purregebyr og renter i henhold til
              gjeldende lovverk.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>8. Pris og prisendringer</span>
              <br />
              Prisene for våre tjenester er oppgitt på vår nettside og
              inkluderer alle kostnader knyttet til mat og levering.
              <br />
              <br />

              Matvarehuset forbeholder seg retten til å justere prisene, med 30
              dagers varsel per e-post.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>9. Avbestilling og endringer</span>
              <br />
              Avbestillinger må gjøres senest kl. 12:00 fire virkedager før
              avtalt leveringsdag. Ved senere avbestilling belastes kunden for
              hele beløpet.
              <br />
              <br />

              Endringer i bestillingen må gjøres innen samme frist som for
              avbestilling.

              <br />
              <br />

              Det er anledning til å pause et abonnement, da vil kundeforholdet
              bestå. Du kan starte opp igjen kundeforholdet når du ønsker.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>10. Markedsføring</span>
              <br />

              Når du har et abonnement hos Matvarehuset vil vi kunne kontakte
              deg via e-post, telefon eller SMS for å gi informasjon om
              tjenesten og våre produkter. Du kan når som helst reservere deg
              mot slik markedsføring ved å kontakte Matvarehuset sitt
              kundesenter via e-post eller telefon 400 06 499.

              <br />
              <br />

              Du vil uansett fortsatt motta viktig informasjon som
              ordrebekreftelser og endringer i vilkårene. Selv om abonnementet
              ditt er satt på pause, vil vi kunne sende deg markedsføring i tråd
              med Markedsføringsloven.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>11. Personvern</span>
              <br />

              Vi behandler personopplysninger i samsvar med gjeldende
              personvernlovgivning. For mer informasjon, se vår
              <router-link to="/privacy">personvernerklæring</router-link> på
              nettsiden.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>12. Endringer i vilkår og betingelser</span>
              <br />

              Matvarehuset forbeholder seg retten til å endre disse vilkårene og
              betingelsene.

              <br />
              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>13. Kontaktinformasjon </span>
              <br />

              For spørsmål eller henvendelser, vennligst kontakt oss på:
              <br />
              Telefon: 400 06 499
              <br />

              E-post:
              <a href="mailto:firmapost@matvarehuset.no">
                firmapost@matvarehuset.no</a
              >

              <br />
              Adresse: Hallheimslien 12, 5184 Olsvik

              <br />
              <br />
            </p>
          </div>
        </div>

        <Orangebutton
          label="Hjem"
          :destination="{ path: 'home' }"
          class="terms-container__button-parent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import Orangebutton from "@/components/Orangebutton.vue";

export default {
  components: {
    ButtonComponent,
    Orangebutton,
  },
};
</script>
