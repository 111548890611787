<script>
export default {
  props: {
    footerText1: String,
    footerText2: String,
    footerText3: String,
    footerText4: String,
  },
};
</script>

<template>
  <div class="footer">
    <div class="container footer__content">
      <div class="footer__company">
        <p>{{ footerText1 }}</p>
        <p>{{ footerText2 }}</p>
        <p>
          {{ footerText3 }}
          <a
            href="mailto:firmapost@matvarehuset.no"
            class="footer__underline"
            >{{ footerText4 }}</a
          >
        </p>
      </div>

      <router-link to="/" class="footer__logo">
        <img
          width="100px"
          src="@/assets/elements/logo.png"
          class="footer__logo-image"
          alt="Matvarehuset logo"
        />
      </router-link>

      <div class="footer__links">
        <div class="footer__links-company">
          <p>MATVAREHUSET © {{ new Date().getFullYear() }}.</p>
        </div>
        <div class="footer__links-flex">
          <router-link to="/privacy">
            <p>Personvernerklæring</p>
          </router-link>

          <router-link to="/cookies">
            <p>Cookies</p>
          </router-link>

          <router-link to="/terms">
            <p>Vilkår og betingelser</p>
          </router-link>

          <router-link to="/legal">
            <p>Åpenhetsloven</p>
          </router-link>
        </div>
      </div>
    </div>

    <div class="footer__border"></div>
    <div class="footer__text">
      <p>
        Denne nettsiden er laget av
        <a
          class="footer__text__link footer__underline"
          href="https://www.pulsecom.no/"
          >Pulse Communications</a
        >
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.footer {
  padding-top: 2rem;
  padding-left: 10rem;
  padding-right: 10rem;
  background-color: var(--primary);
  font-weight: 900;

  @media (max-width: $tablet) {
    padding: 2rem 1rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 1rem;
    }

    p {
      font-size: 1.25rem;
      color: var(--secondary-contrast);
    }
  }

  &__company {
    width: 40%;

    @media (max-width: $tablet) {
      width: 100%;
      text-align: center;
      order: 2;
    }
  }

  &__logo {
    background-color: var(--secondary);
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $tablet) {
      order: 1;
    }
  }

  &__logo-image {
    width: 73px;
    height: 83px;
  }

  &__links {
    width: 40%;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: end;

    @media (max-width: $tablet) {
      width: 100%;
      order: 3;
      align-items: center;
    }
  }

  &__links-company {
    @media (max-width: $tablet) {
      order: 2;
    }
  }

  &__links-flex {
    display: flex;
    gap: 1.5rem;

    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: 1450px) {
      flex-direction: column;
      gap: 0.5rem;
      align-items: end;
    }

    @media (max-width: $tablet) {
      order: 1;
      flex-direction: column;
      align-items: center;
    }
  }

  &__border {
    margin-top: 20px;
    width: 100%;
    height: 1.5px;
    background-color: white;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    text-align: center;

    p {
      font-size: 14px;
      line-height: 23px;
      color: white;
    }

    &__link {
      text-decoration: underline;
    }

    @media (max-width: $mobile) {
      padding: 25px;
    }
  }

  h2 {
    font-size: 0.875rem;
    color: white;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &__underline {
    text-decoration: underline !important;
  }
}
</style>
