<template><PrivacySection /></template>

<script>
import PrivacySection from "@/components/PrivacySection.vue";

export default {
  components: {
    PrivacySection,
  },
};
</script>

<style lang="scss"></style>
