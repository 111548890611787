<template>
  <div class="faq">
    <h1>{{ faqSection.title }}</h1>

    <p>
      {{ faqSection.subtitle }}
    </p>

    <div class="dropdown-parent">
      <Dropdown
        v-for="(item, index) in faqSection.faqBlock"
        :title="item.title"
        :subtitle="item.description"
        :index="index"
      />
    </div>

    <Tryfree
      :registerPreText="registerPreText"
      :registerTitle="registerTitle"
      :registerPostText="registerPostText"
    />

    <OrangeButton
      label="Hjem"
      :destination="{ path: 'home' }"
      class="faq__button-parent"
    />
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import Tryfree from "@/components/Tryfree.vue";
import OrangeButton from "@/components/Orangebutton.vue";

export default {
  components: {
    Dropdown,
    Tryfree,
    OrangeButton,
  },
  props: {
    faqSection: Object,
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
  },
};
</script>

<style lang="scss">
.faq {
  width: 100%;
  max-width: 1100px;
  min-height: 100vh;
  padding: 0rem 1.18rem;
  padding-top: 12.5rem;
  margin: auto;
  text-align: start;

  @media (max-width: $mobile) {
    padding-top: 7.5rem;
  }

  p {
    margin: 1rem 0rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  &__button-parent {
    margin-bottom: 5rem;
  }
}

.dropdown-parent {
  margin-top: 2rem;
}
</style>
