<template>
  <div class="tryfree">
    <p class="tryfree__grey-text ">{{ registerPreText }}</p>

    <OrangeButton
      :label="registerTitle"
      :destination="{ path: 'home', hash: '#delta' }"
      :alignCenter="alignCenter"
    />

    <p v-if="showDisclaimer" class="tryfree__grey-text">
      {{ registerPostText }}
    </p>
  </div>
</template>

<script>
import OrangeButton from "@/components/Orangebutton.vue";

export default {
  components: { OrangeButton },
  props: {
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
    showDisclaimer: Boolean,
    alignCenter: Boolean,
  },
};
</script>

<style lang="scss">
.tryfree {
  &__grey-text {
    font-size: 1.06rem;
    color: #000000;
    margin: auto;
    text-align: center !important;
    font-weight: 500;
    margin-top: 0.75rem;
  }

  &__button-orange {
    display: block;
    background-color: var(--primary);
    padding: 0.75rem 3rem;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;

    span {
      font-size: 1.5rem;
      font-family: var(--primary-font);
      color: var(--secondary-contrast);
    }
  }
}
</style>
