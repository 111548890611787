<template>
  <div class="aboutus" id="aboutus">
    <h2>{{ aboutTitle }}</h2>

    <div class="aboutus__section aboutus__flex">
      <img class="aboutus__image" :src="aboutImage1" alt="Matrett" />

      <div class="aboutus__text">
        <h3>{{ aboutSection1Title }}</h3>
        <br />

        <p>
          {{ aboutSection1Text }}
        </p>

        <!-- <p>
          Matvarehuset har holdt på med matomsorg siden 2007, og er Norges
          største leverandør av måltider til seniorer. Alle måltidene er
          kvalitetssikret av ernæringsfysiologer og laget med kjærlighet av
          kokker. Vi leverer kjente og kjære retter av god kvalitet som er
          lokalt produsert i Bergen.
        </p>

        <br />

        <p>
          Et godt kundeforhold er noe vi verdsetter høyt, og vi vil derfor
          alltid tilpasse måltidene etter dine behov, smakspreferanser og
          allergier. Det er enkelt å justere antall middager og desserter ved
          behov, og skulle du reise bort kan du enkelt sette abonnementet på
          pause. Vi etterstreber alltid å bruke norske råvarer.
        </p> -->
      </div>
    </div>

    <div class="aboutus__divider"></div>

    <div class="aboutus__section aboutus__flex aboutus__flexreverse">
      <img
        class="aboutus__image"
        :src="aboutImage2"
        alt="Tilberedning av mat"
      />

      <div class="aboutus__text">
        <h3>{{ aboutSection2Title }}</h3>
        <br />
        <p>
          {{ aboutSection2Text }}
        </p>
      </div>
    </div>

    <div class="aboutus__divider"></div>

    <div class="aboutus__section aboutus__flex">
      <img
        class="aboutus__image"
        :src="aboutImage3"
        alt="Overlevering av mat"
      />

      <div class="aboutus__text">
        <h3>{{ aboutSection3Title }}</h3>

        <br />

        <p>
          {{ aboutSection3Text }}
        </p>
      </div>
    </div>

    <div class="aboutus__divider"></div>

    <div class="aboutus__section aboutus__flex aboutus__flexreverse">
      <img class="aboutus__image" :src="aboutImage4" alt="Sjåfør i bil" />

      <div class="aboutus__text">
        <h3>{{ aboutSection4Title }}</h3>

        <br />

        <p>
          {{ aboutSection4Text }}
        </p>
      </div>
    </div>

    <Tryfree
      :registerPreText="registerPreText"
      :registerTitle="registerTitle"
      :registerPostText="registerPostText"
      :showDisclaimer="true"
      :alignCenter="true"
    />

    <div class="aboutus__faq">
      <h2>{{ faqLinkTitle }}</h2>
      <p>{{ faqLinkSubtitle }}</p>
    </div>

    <OrangeButton
      :label="faqLinkButton"
      :destination="{ path: 'faq' }"
      :alignCenter="true"
    />
  </div>
</template>

<script>
import Tryfree from "@/components/Tryfree.vue";
import OrangeButton from "@/components/Orangebutton.vue";

export default {
  components: {
    Tryfree,
    OrangeButton,
  },
  props: {
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
    aboutTitle: String,
    aboutSection1Title: String,
    aboutSection1Text: String,
    aboutSection2Title: String,
    aboutSection2Text: String,
    aboutSection3Title: String,
    aboutSection3Text: String,
    aboutSection4Title: String,
    aboutSection4Text: String,
    faqLinkTitle: String,
    faqLinkSubtitle: String,
    faqLinkButton: String,
    aboutImage1: String,
    aboutImage2: String,
    aboutImage3: String,
    aboutImage4: String,
  },
};
</script>

<style lang="scss">
.aboutus {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding-top: 7.5rem;
  margin-top: -5rem;
  margin-bottom: 5rem;

  &__flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
  }

  &__flexreverse {
    flex-direction: row-reverse;
  }

  &__section {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  &__text {
    width: 545px;
  }

  &__image {
    width: 100%;
    max-width: 550px;
    border-radius: 17px;
  }

  h2 {
    text-align: center;
  }

  &__divider {
    border: 1px solid #eae7dd;
    width: 100%;
    max-width: 1300px;
  }

  &__faq {
    margin-top: 8rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  @media (max-width: $tablet) {
    padding: 0rem 1.18rem;
    padding-top: 7.5rem;
  }
}
</style>
